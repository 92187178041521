import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Size } from '../../model';

/**
 * progress è il valore in percentuale (da 0 a 100). Se viene passato in input
 * progress = 100, la SVG non è in grado di differenziare l'angolo di 0° (0%)
 * con un angolo di 360° (100%). Motivo per cui nella onChanges viene applicato
 * un controllo con eventuale modifica a 99.9%
 *
 */
@Component({
    selector: 'interacta-progress-loader',
    templateUrl: './progress-loader.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgClass, AsyncPipe],
})
export class ProgressLoaderComponent implements OnChanges {
    @Input() progress = 0;
    @Input() color: 'primary' | 'green' = 'primary';
    @Input() emptyCircleColor: 'current' | 'gray' = 'current';
    @Input() size: Extract<
        Size,
        'regular' | 'small' | 'large' | 'extra-large'
    > = 'regular';

    position = 12;
    radius = 10;
    pathD$ = new BehaviorSubject<string>('');

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['progress']) {
            const d = this.getPathString(
                this.progress === 100 ? 99.9 : this.progress,
            );
            this.pathD$.next(d);
        }
    }

    private getPathString(progress: number): string {
        const startAngle = 0;
        const endAngle = (progress * 360) / 100;
        const start = this.polarToCartesian(endAngle);
        const end = this.polarToCartesian(startAngle);

        const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

        const d = [
            'M',
            start.x,
            start.y,
            'A',
            this.radius,
            this.radius,
            0,
            largeArcFlag,
            0,
            end.x,
            end.y,
        ].join(' ');

        return d;
    }

    private polarToCartesian(angleInDegrees: number): { x: number; y: number } {
        const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

        return {
            x: this.position + this.radius * Math.cos(angleInRadians),
            y: this.position + this.radius * Math.sin(angleInRadians),
        };
    }
}
