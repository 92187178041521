<div
    *ngIf="{ pathD: pathD$ | async } as $"
    class="block"
    [ngClass]="{
        'text-text-primary': color === 'primary',
        'text-system-success': color === 'green',
        'size-48': size === 'extra-large',
        'size-32': size === 'large',
        'size-24': size === 'regular',
        'size-16': size === 'small'
    }"
>
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <circle
            class="stroke-4"
            [attr.cx]="position"
            [attr.cy]="position"
            [attr.r]="radius"
            [ngClass]="{
                'stroke-gray-100': emptyCircleColor === 'gray',
                'stroke-current opacity-25': emptyCircleColor === 'current'
            }"
        ></circle>
        <path
            class="stroke-4 stroke-current opacity-75"
            [attr.d]="$.pathD"
        ></path>
    </svg>
</div>
